import {useEffect, useState} from "react";
import Image from "../Shared/Image";

export default function ImageTabs({images}) {

    const [selected, setSelected] = useState(0);
    const [zoom, setZoom] = useState(false);
    useEffect(() => {
        setSelected(0)
    }, [images])

    return (
        <div className={"flex flex-col gap-2"}>
            <div className="aspect-w-1 aspect-h-1 w-full">
                {images && images.map((image, index) => (
                    <div
                        className={`flex items-center justify-center ${!zoom ? 'hover:cursor-zoom-in' : 'hover:cursor-zoom-out h-full w-full p-2'} 
                    ${selected === index ? 'block' : 'hidden'} ${selected === index && zoom ?
                            'fixed flex items-center justify-center z-50 top-0 left-0 bg-gray-300 bg-opacity-75' : ''}`}
                        key={image.id} onClick={() => {
                        setZoom(!zoom);
                    }}>
                        <Image image={image}/>
                    </div>))}
                {!images || images.length === 0 && <div className={"flex items-center justify-center h-full w-full"}>
                    <img
                        src={`https://www.anleggsdeler.no/static/img/defaultimagead-01.jpg`}
                        alt={"default image"}
                        className="object-contain object-center sm:rounded-lg"
                    />
                </div>}
            </div>
            <div className={"mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none"}>
                <div className={"grid grid-cols-4 gap-6"}>

                    {images && images.map((image, index) =>
                        <div key={index}
                             onClick={() => {
                                 setSelected(index)
                             }}
                             className={"relative flex h-24 hover:shadow-2xl cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900"}>
                    <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img
                              src={`${process.env.REACT_APP_API_ENDPOINT}/attachment/${image.id}/thumbnail`}
                              alt={image.filename} className=" h-full w-full object-contain object-center"/>
                        </span>
                            <span
                                className={` ${selected === index ? '!ring-yellow' : 'ring-transparent'} pointer-events-none absolute inset-0 rounded-md !ring-2 !ring-offset-2`}
                                aria-hidden="true"
                            />
                        </div>)}
                </div>
            </div>
        </div>
    )
}