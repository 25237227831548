import {Fragment, useEffect, useState} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/outline'
import {Link, useParams} from "react-router-dom";
import {getAllOrdersService, getLoggedInUserService} from "../../service";
import ImageThumbnail from "../Shared/ImageThumbnail";
import {connect} from "react-redux";


function MyPage(props) {
    const params = useParams();
    const {orders} = useFetchOrders(params.customerId);
    const [costumer, setCostumer] = useState({});
    useEffect(() => {
        if (props.userInfo && props.userInfo.id) {
            getLoggedInUserService(props.userInfo.id).then(response => {
                if (response.status && response.status !== 200) {
                    console.log("error", response.status);
                } else {
                    setCostumer(response);
                }
            }).catch(error => {
                console.error("error", error);
            })
        }

    }, [props.userInfo])
    return (
        <div className="bg-white">
            {props.userInfo && <div className="bg-white">
                <div className={"flex flex-col gap-2 max-w-5xl mx-auto "}>
                    <div className={"flex flex-col rounded border divide-y"}>
                        <span className={"bg-slate-100 text-gray-900 text-lg p-2"}>Min Detaljer</span>
                        <div className={"flex w-full divide-x"}>
                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                className={"text-xs text-gray-400"}>Navn:</span>{props.userInfo.firstName}</div>
                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                className={"text-xs text-gray-400"}>Etter navn:</span>{props.userInfo.lastName}</div>
                        </div>
                        <div className={"flex items-center gap-3 p-2"}><span
                            className={"text-xs text-gray-400"}>Mobil:</span>{costumer.phone ? costumer.phone : ''}
                        </div>
                        <div className={"flex items-center gap-3 p-2"}><span
                            className={"text-xs text-gray-400"}>Epost:</span>{props.userInfo.email}</div>
                        {props.crmPerson.crmCompanyName && <div className={"flex items-center gap-3 p-2"}><span
                            className={"text-xs text-gray-400"}>Kunde:</span>{props.crmPerson.crmCompanyName}</div>}
                    </div>
                    {Object.keys(costumer).length > 0 && <div className={"flex flex-col rounded border divide-y"}>
                        <span className={"bg-slate-100 text-gray-900 text-lg p-2"}>Leveringsadresser</span>
                        <div className={"flex items-center gap-3 p-2"}><span
                            className={"text-xs text-gray-400"}>Adresse:</span>{costumer.primaryAddress.address1}</div>
                        <div className={"flex w-full divide-x"}>
                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                className={"text-xs text-gray-400"}>Postkode:</span>{costumer.primaryAddress.postalCode}
                            </div>
                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                className={"text-xs text-gray-400"}>Poststed:</span>{costumer.primaryAddress.postalArea}
                            </div>
                        </div>
                        {costumer.primaryAddress.city && costumer.primaryAddress.country && <div
                            className={"flex items-center gap-3 p-2"}>{costumer.primaryAddress.city} {costumer.primaryAddress.country} </div>}
                    </div>}
                </div>
            </div>}
            <div className="py-4">
                <div className="max-w-5xl mx-auto">
                    <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        Bestillingshistorikk
                    </h1>
                    <p className="mt-2 text-sm text-gray-500">
                        Sjekk statusen til nylige bestillinger, administrer returer og oppdag lignende produkter.
                    </p>

                </div>

                <div className="mt-16">
                    <h2 className="sr-only">Nylige bestillinger</h2>
                    <div className="mx-auto w-full max-w-5xl sm:px-2">
                        <div className="space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
                            {orders.map((order) => (
                                <div
                                    key={order.orderNumber}
                                    className="border-gray-200 bg-white shadow-sm sm:rounded-lg border w-full">
                                    <div
                                        className="flex flex-col border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
                                        <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                                            <div>
                                                <dt className="font-medium text-gray-900">Ordre#</dt>
                                                <dd className="mt-1 text-gray-500">{order.orderNumber}</dd>
                                            </div>
                                            <div className="hidden sm:block">
                                                <dt className="font-medium text-gray-900">Ordredato</dt>
                                                <dd className="mt-1 text-gray-500">
                                                    <time
                                                        dateTime={order.createdDatetime}>{new Date(order.created).toDateString()}</time>
                                                </dd>
                                            </div>
                                        </dl>

                                        <div
                                            className="lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                                            <Link
                                                to={`/shop/orders/${order.id}`}
                                                className="flex items-center justify-center rounded-md border border-gray-300 bg-white py-2 px-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                <span>Vis detaljer</span>
                                                <span className="sr-only">{order.orderNumber}</span>
                                            </Link>
                                        </div>
                                    </div>

                                    {/* Products */}
                                    <h4 className="sr-only">Items</h4>
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {order.orderLines.map((orderLine) => (
                                            <li key={orderLine.product.id} className="p-4 sm:p-6">
                                                <div className="flex items-center sm:items-start">
                                                    <div
                                                        className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
                                                        <ImageThumbnail
                                                            image={orderLine.product.productImages.length>0?orderLine.product.productImages[0]:null}/>
                                                    </div>
                                                    <div className="ml-6 flex-1 text-sm">
                                                        <div
                                                            className="font-medium text-gray-900 sm:flex sm:justify-between">
                                                            <h5>{orderLine.product.name}</h5>
                                                            <p className="mt-2 sm:mt-0">{orderLine.product.price}</p>
                                                        </div>
                                                        <p className="hidden text-gray-500 sm:mt-2 sm:block">{orderLine.product.description}</p>
                                                    </div>
                                                </div>

                                                <div className="mt-6 sm:flex sm:justify-between">
                                                    <div
                                                        className="mt-6 flex items-center space-x-4 divide-x divide-gray-200 border-t border-gray-200 pt-4 text-sm font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
                                                        <div className="flex flex-1 justify-center">
                                                            <Link
                                                                to={`/shop/product/${orderLine.product.id}`}
                                                                className="whitespace-nowrap text-indigo-600 hover:text-indigo-500"
                                                            >
                                                                Vis produkt
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({userInfo, crmPerson}) => {
    return {userInfo: userInfo, crmPerson: crmPerson}
}
export default connect(mapStateToProps)(MyPage)
const useFetchOrders = (customerId) => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getAllOrdersService(customerId).then(orders => {
            if (orders)
                setOrders(orders)
        }).catch(err => {
            console.error("error", err)
        })
    }, [customerId])
    return {orders}
}