import {useState} from "react";

export default function ImageThumbnail({image}) {
    const [loaded,setLoaded] = useState(false)
    const defaultImageUrl = "https://www.anleggsdeler.no/static/img/defaultimagead-01.jpg";
    return(<div className={"flex justify-center relative min-h-full h-full w-full rounded-md "}>
        {!loaded&&<div className={"absolute z-10 flex items-center justify-center h-full"}><span
            className={"animate-spin h-12 w-12 rounded-full mx-auto my-auto border-4 border-orange-400 border-t-green-900"}></span></div>}
        <img
            src={image?`${process.env.REACT_APP_API_ENDPOINT}/attachment/${image.id}/thumbnail`:defaultImageUrl}
            alt={image?image.filename:"default image"}
            onLoad={()=>setLoaded(true)}
            className="object-cover object-center min-h-full min-w-full rounded"
        />
    </div>)
}